




import { defineComponent } from '@vue/composition-api'
import TendersList from '@/views/Tender/views/TendersList.vue'

export default defineComponent({
  name: 'SellingTenders',
  components: {
    TendersList,
  },
})
